import React from "react"
import { Link } from "gatsby"
import logo from '../../content/assets/coderescue.svg'

export const Footer = () => {
  return (
    <footer>
      <div class="container-1">
        <div class="box-1">
          <h1>
            <Link to='/'>
              <img src={logo} ></img>
            </Link>
          </h1>
          <label class="a1">Follow Us</label>
          <br />
          <a href="https://twitter.com/home" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              image-rendering="optimizeQuality"
              //fill-rule="evenodd"
              //clip-rule="evenodd"
              width="36px"
              height="36px"
            >
              <path
                d="M333328 63295c-12254 5480-25456 9122-39241 10745 14123-8458 24924-21861 30080-37819-13200 7807-27871 13533-43416 16596-12499-13281-30252-21537-49919-21537-37762 0-68336 30591-68336 68330 0 5326 591 10537 1748 15562-56820-2880-107194-30081-140915-71467-6049 10435-9250 22300-9250 34367v8c0 23696 12031 44654 30389 56876-11202-333-21739-3457-30991-8519v854c0 33138 23554 60789 54852 67039-5734 1557-11787 2417-18023 2417-4417 0-8673-455-12905-1224 8742 27139 33975 46923 63857 47500-23430 18356-52858 29286-84939 29286-5537 0-10931-339-16318-984 30326 19458 66251 30727 104844 30727 125735 0 194551-104198 194551-194543 0-3002-67-5911-191-8852 13354-9553 24932-21609 34097-35333l31-31-6 4z"
                fill="#1da1f2"
              />
            </svg>
          </a>
          &nbsp;
          <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              image-rendering="optimizeQuality"
              fill-rule="evenodd"
              clip-rule="evenodd"
              width="36px"
              height="36px"
            >
              <path
                d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm18069 106436h30115V70296h-30115c-23244 0-42158 18913-42158 42158v18070h-24095v36143h24089v96371h36142v-96371h30115l6022-36142h-36137v-18070c0-3266 2757-6022 6022-6022v4z"
                fill="#3b5998"
              />
            </svg>
          </a>
          &nbsp;
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              image-rendering="optimizeQuality"
              fill-rule="evenodd"
              clip-rule="evenodd"
              width="36px"
              height="36px"
            >
              <defs>
                <linearGradient
                  id="a"
                  gradientUnits="userSpaceOnUse"
                  x1="250181"
                  y1="308196"
                  x2="83152.4"
                  y2="25137"
                >
                  <stop offset="0" stop-color="#f58529" />
                  <stop offset=".169" stop-color="#feda77" />
                  <stop offset=".478" stop-color="#dd2a7b" />
                  <stop offset=".78" stop-color="#8134af" />
                  <stop offset="1" stop-color="#515bd4" />
                </linearGradient>
              </defs>
              <path
                d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40642 71361h81288c30526 0 55489 24654 55489 54772v81069c0 30125-24963 54771-55488 54771l-81289-1c-30526 0-55492-24646-55492-54771v-81069c0-30117 24966-54771 55492-54771zm40125 43843c29663 0 53734 24072 53734 53735 0 29667-24071 53735-53734 53735-29672 0-53739-24068-53739-53735 0-29663 24068-53735 53739-53735zm0 18150c19643 0 35586 15939 35586 35585 0 19647-15943 35589-35586 35589-19650 0-35590-15943-35590-35589s15940-35585 35590-35585zm51986-25598c4819 0 8726 3907 8726 8721 0 4819-3907 8726-8726 8726-4815 0-8721-3907-8721-8726 0-4815 3907-8721 8721-8721zm-85468-20825h68009c25537 0 46422 20782 46422 46178v68350c0 25395-20885 46174-46422 46174l-68009 1c-25537 0-46426-20778-46426-46174v-68352c0-25395 20889-46177 46426-46177z"
                fill="url(#a)"
              />
            </svg>
          </a>
          &nbsp;
          <a href="https://www.pinterest.com" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              image-rendering="optimizeQuality"
              fill-rule="evenodd"
              clip-rule="evenodd"
              width="36px"
              height="36px"
            >
              <path
                d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40062 259007c10251-9143 17289-22164 20724-35518 1385-5264 7037-26819 7037-26819 3657 7038 14462 12967 25822 12967 34022 0 58513-31252 58513-70206 0-37291-30420-65163-69541-65163-48706 0-74527 32692-74527 68266 0 16513 8810 37126 22884 43664 2106 1052 3269 554 3768-1552 333-1607 2272-9198 3158-12800 278-1108 167-2161-776-3269-4654-5652-8422-16069-8422-25711 0-24880 18840-48983 50922-48983 27706 0 47099 18840 47099 45880 0 30531-15404 51698-35463 51698-11082 0-19393-9143-16679-20391 3214-13410 9364-27872 9364-37513 0-8644-4599-15848-14296-15848-11359 0-20391 11692-20391 27428 0 9974 3324 16734 3324 16734s-11193 47265-13243 56076c-2272 9752-1385 23439-388 32360l997 8755 111-56v-1z"
                fill="#bd081c"
              />
            </svg>
          </a>{" "}
          &nbsp;
        </div>
        <hr class="hr0"></hr>
        <div class="box-2">
          <h2 class="a1">About Us</h2>
          <Link class="aLink Link" to="/about-us/">
            <span class="Link">About Us</span>
          </Link>
          <br />
          <Link class="aLink Link" to="/contact-us/">
            Contact Us
          </Link>
          <br />
          <a
            class="aLink Link"
            href="https://forms.gle/aMA9RQ19ud2p63ux6"
            target="_blank"
          >
            Submit Your Code
          </a>
        </div>
        <hr class="hr1"></hr>
        <div class="box-3">
          <h2 class="a1">Policies</h2>
          <Link class="aLink Link" to="/termsandconditions/">
            Terms and Conditions
          </Link>
          <br />
          <Link class="aLink Link" to="/privacy/">
            Privacy Policy
          </Link>
          <br />

          <Link class="aLink Link" to="/cookie/">
            Cookie Policy
          </Link>
        </div>
      </div>
    </footer>
  )
}
