import React, { useEffect } from "react"
import { Navbar } from "./navbar"
import { Footer } from "./footer"


const Layout = ({ location, title, children }) => {

  const checkScrollTop = () => {
    const btt = document.getElementById('back-to-top')
    if (window.pageYOffset > 400) {
      btt.removeAttribute('hidden')
    } else if (window.pageYOffset <= 400) {
      btt.setAttribute('hidden', '')
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
  }, [])
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <header>
        <Navbar></Navbar>
      </header>
      <div id='back-to-top' className='back-to-top-wrapper' onClick={scrollTop} hidden>
        <svg className='arrow' viewBox="0 0 20 20" fill="currentColor" class="arrow-narrow-up w-6 h-6"><path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
      </div>
      <main>{children}</main>
      <Footer></Footer>
    </div>
  )
}

export default Layout
