import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import logo from '../../content/assets/coderescue.svg'
/* import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

// loads the Icon plugin
UIkit.use(Icons) */

export const Navbar = () => {
  const [filteredList = [], setFilteredList] = useState();
  const { allStrapiArticle: data } = useStaticQuery(graphql`
  {
  allStrapiArticle(filter: { status: { eq: "published" } }) {
    edges {
      node {
        slug
        title
        updatedAt
        meta_description
      ttr:childStrapiArticleContent {
        childMdx {
          timeToRead
        }
      }
    }
  }
}
}
`)


  let searchToggleState = false


  useEffect(() => {
    let date = new Date()
    document.getElementById('date').innerText = date.toLocaleDateString()
  }, [])



  setInterval(() => {
    let t = new Date()
    let time = t.toLocaleTimeString()
    document.getElementById('time').innerText = time
  }, 1000);



  const searchToggle = () => {
    searchToggleState = !searchToggleState
    if (searchToggleState) {
      document.body.style.overflowY = 'hidden'
      document.getElementById('search-bar').style.display = 'block'
    }
    else if (!searchToggleState) {
      document.body.style.overflowY = 'scroll'
      document.getElementById('search-bar').style.display = 'none'
    }
  }

  const searchHandler = (query) => {
    const filteredPosts = data.edges.filter(el => {

      if (el.node.title.toLowerCase().includes(query.toLowerCase())) return true
      else return false

    })
    setFilteredList(filteredPosts)
  }

  const toggleScroll = () => {
    document.body.style.overflowY = 'scroll'
  }
  return (
    <div className="navbar-container">
      <div class="site-logo-container">
        <div className='site-logo'>
          <Link to='/'>
            <img className='logo' src={logo} ></img>
          </Link>
          <br />
          <div className='date-and-time'>
            <span id='date' className='date-and-time-left'></span>
            <span id='time' className='date-and-time-right'></span>
          </div>
        </div>
      </div>
      <nav class="uk-navbar-container uk-visible@s" data-uk-navbar>
        <div class="uk-navbar-center">
          <div class="uk-navbar-center-left">
            <div>
              <ul class="uk-navbar-nav">

                {/* <li>
                  <a href="#">Parent</a>
                  <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                      <li class="uk-active">
                        <a href="#">Active</a>
                      </li>
                      <li>
                        <a href="#">Item</a>
                      </li>
                      <li>
                        <a href="#">Item</a>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li /* class="uk-active" */>
                  <Link to='/posts'>
                    <a class="uk-navbar-item">
                      All Posts
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <Link to='/'>
            <a class="uk-navbar-item">
              HOME
            </a>
          </Link>
          <div class="uk-navbar-center-right">
            <div>
              <ul class="uk-navbar-nav">
                <li /* class="uk-active" */>
                  <Link to='/contact-us'>
                    <a class="uk-navbar-item">
                      Contact Us
                    </a>
                  </Link>
                </li>
                <li /* class="uk-active" */>
                  <a class="uk-navbar-item">
                    <div className='search-icon' onClick={() => searchToggle()}>
                      <svg viewBox="0 0 20 20" fill="currentColor" class="search w-6 h-6"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="uk-hidden@s">
        <button
          class="uk-button uk-button-default uk-margin-small-left uk-margin-small-top uk-margin-small-bottom"
          type="button"
          data-uk-toggle="target: #navbar-drawer"
        >
          Menu
        </button>
        <div className='search-icon search-icon-mobile' onClick={() => searchToggle()}>
          <svg viewBox="0 0 20 20" fill="currentColor" class="search w-6 h-6"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
        </div>
        <div id="navbar-drawer" data-uk-offcanvas>
          <div class="uk-offcanvas-bar">
            <button
              class="uk-offcanvas-close"
              type="button"
              data-uk-close
            ></button>
            <Link to='/' >
              <h3>HOME</h3>
            </Link>
            <Link to='/posts' >
              <h3>All Posts</h3>
            </Link>
            <Link to='/contact-us' >
              <h3>Contact Us</h3>
            </Link>
          </div>
        </div>
      </div>
      <div id='search-bar' className='search-bar-wrapper'>
        <div className='close-icon' onClick={searchToggle}>
          <svg viewBox="0 0 20 20" fill="currentColor" class="x w-6 h-6"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </div>
        <div className='search-bar-container'>
          <input className='search-bar' type='text' onChange={e => searchHandler(e.target.value)}></input>
          <div className='search-result-wrapper'>
            {
              filteredList.map(post => {
                const date = new Date(post.node.updatedAt)
                return (
                  <Link to={post.node.slug} key={post.node.slug} onClick={toggleScroll}>
                    <div className='search-result'>
                      <h3 className='search-result-title'>{post.node.title}</h3>
                      <small className='search-result-tnd'>{date.toLocaleDateString() + ' | ' + post.node.ttr.childMdx.timeToRead} min read</small>
                      <hr className='search-result-line' />
                    </div>
                  </Link>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
